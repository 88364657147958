<div class="footer">
  <div>
  </div>
  <div>
    <div class="cx cy text-footer-menu">
      <div [routerLink]="['']" routerLinkActive="selected-menu" [routerLinkActiveOptions]= "{exact: true}" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Главная')}}</div>
      <div [routerLink]="['/projects']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Проекты')}}</div>
      <div [routerLink]="['/design']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Дизайн')}}</div>
      <div [routerLink]="['/services']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Услуги')}}</div>
      <div [routerLink]="['/certs']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Сертификаты')}}</div>
      <div [routerLink]="['/vacancy']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Вакансии')}}</div>
      <div [routerLink]="['/contacts']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('Контакты')}}</div>
      <div [routerLink]="['/sawc']" routerLinkActive="selected-menu" class="px-10 py-10 cursor-pointer hover-text-footer">{{t.translate('СОУТ')}}</div>
    </div>
  </div>
  <div>
  </div>
  <div class="bottom-footer">
    <div class="cx cy w-100">
      <div>
        <div class="cy cx text-center text-footer">{{t.translate('Инфопартнер')}}</div>
        <div class="cy cx">
          <a target="_blank" href="https://paluba.media/"><img class="logo-media" height="30" src="../../assets/logo_footer-2.png" alt=""></a>
        </div>
      </div>
    </div>

    <div class="center w-100">
      <div class="cx cy direction-column text-footer">
        <div>© All Rights Reserved.</div>
        <div>Design by Nautic Rus, 2024</div>
        <div class="df mt-10">
          <div class="cyy">
            <a href="https://t.me/s/nauticrus" target="_blank">
              <img height="24" src="../../assets/telegram.svg" alt="" class="mx-5">
            </a>
          </div>
          <div class="cyy">
            <a href="https://vk.com/nauticrus" target="_blank">
              <img height="24" src="../../assets/vkontakte.svg" alt="" class="mx-5">
            </a>
          </div>
          <div class="cyy">
            <a href="https://www.instagram.com/nauticrus" target="_blank">
              <img height="24" src="../../assets/instagram.svg" alt="" class="mx-5">
            </a>
          </div>
        </div>
      </div>
      <div class="cx cy pt-1">
      </div>
    </div>
    <div class="cx cy w-100">
      <div>
        <div class="cy text-center pb-1 text-footer">{{t.translate('Наши партнеры в Исландии')}}</div>
        <div class="cy cx">
          <a target="_blank" href="https://www.nautic.is"><img class="mx-1" height="45" src="../../assets/nautic-is.png" alt=""></a>
        </div>
      </div>
      <div class="cyy">
        <img height="70px" src="../../assets/qr.png" alt="" class="mx-1">
      </div>
      <a target="_blank" href="https://rs-class.org"><img class="mx-1" height="80px" src="../../assets/registerlogo.png" alt=""></a>
      <div>

      </div>
    </div>
  </div>
</div>

